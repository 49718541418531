import React from "react"
import { Helmet } from "react-helmet"

import ContentModules from "../components/page/content-modules"
import Layout from "../components/layout"

const IndexPage = ({ data }) => {
  const { frontPage } = data

  return (
    <Layout isHomepage={true}>
      <Helmet title={"LSJ Studio"} />
      <ContentModules allModules={frontPage.contentModules} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    frontPage: contentfulPage(isHomepage: { eq: "Yes" }) {
      ...PageContent
    }
  }
`
